import React, {useRef, useState} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import userService from "../../../../services/user-services";


const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback absolute -top-5 text-red-500 text-xs">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const AddAdmin = ({setActiveView}) => {
    const form = useRef();
    const checkBtn = useRef();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);

    const onChangeEmail = e => {
        const value = e.target.value;
        setEmail(value)
    }

    const handleSubmit = e => {
        e.preventDefault();
        setMessage("");
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            userService.addAdmin(email).then((res) => {
                setSuccess(true)
            }, (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setMessage(resMessage);
            });
        }
    }

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="heading border-b border-brandSecondary">
                    <h1 className='text-4xl font-bold text-black uppercase'>
                        Felhasználó hozzáadása
                    </h1>
                </div>
                {!success ? <Form onSubmit={handleSubmit} className='flex my-5 flex-col' ref={form}>
                    <div className="border-b border-brandSecondary">
                        <label htmlFor="email" className='text-xs font-bold mb-1 uppercase'>E-mail cím:</label>
                        <Input type="email" id="email" required={true} value={email} onChange={onChangeEmail}
                               className='border-2 text-input w-1/4 mb-5'
                               validations={[required]}/>
                    </div>

                    <div className="pb-5 text-red-500 font-bold">
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex gap-10">
                        <a className="btn-secondary w-fit self--start" onClick={() => setActiveView('list')}>
                            mégsem
                        </a>
                        <button className="btn-primary w-fit self-start fill-white flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='h-5 w-5'>
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                            </svg>
                            Új admin hozzáadása
                        </button>

                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                    </div>

                </Form> : <>
                    <p className='font-bold my-5'>Új felhasználó hozzáadása sikeres!</p>
                    <a className="btn-primary w-fit self--start" onClick={() => setActiveView('list')}>
                        Visszalépés a listaoldalra
                    </a>
                </>}
            </div>

        </>
    );
};

export default AddAdmin;
