import React, {useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {Navigate} from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import classes from "./Users.module.scss";
import AdminList from "./Components/AdminList/AdminList";
import AddAdmin from "./Components/AddAdmin/AddAdmin";
import EditAdmin from "./Components/EditAdmin/EditAdmin";

const Users = () => {
    const {user} = useAuth();
    const [activeView, setActiveView] = useState('list');
    const {role} = useAuth();
    const views = {
        'list': <AdminList setActiveView={setActiveView}/>,
        'add': <AddAdmin setActiveView={setActiveView}/>,
        'edit': <EditAdmin/>
    }

    if (role.data.role !== 'Super Administrator') {
        return <Navigate to="/"/>
    }

    if (!user) {
        return <Navigate to="/login"/>;
    }

    return (
        <Layout>
            <div className={"flex w-full " + classes["background"]}>
                <div className="container px-10 mx-auto">
                    {views[activeView]}
                </div>

            </div>


        </Layout>

    )
};

export default Users;
