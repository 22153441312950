import React, {useRef, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import videoServices from "../../../services/video-services";
import VideoDetails from "../VideoDetails/VideoDetails";

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback absolute -top-5 text-red-500 text-xs">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const AddVideo = () => {
    const form = useRef();
    const checkBtn = useRef();
    const [vimeoId, setVimeoId] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [videoData, setVideoData] = useState({});

    const onChangeVimeoId = e => {
        const value = e.target.value;
        setVimeoId(value)
    }

    const handleSubmit = e => {
        e.preventDefault();
        setMessage("");
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            videoServices.getVideoDataByID(vimeoId).then((res) => {
                setVideoData(res.data);
                console.log(res)
                setSuccess(true);
            }, (error) => {
                const resMessage = error.response.data.developer_message || error.response.data.error;
                setMessage(resMessage);
            });
        }
    }
    return (
        <Layout padded={true}>
            <div className="flex flex-col container mx-auto">
                <div className="heading border-b border-brandSecondary">
                    <h1 className='text-4xl font-bold text-black uppercase'>
                        Videó hozzáadása
                    </h1>
                </div>
                {!success ? <Form onSubmit={handleSubmit} className='flex my-5 flex-col' ref={form}>

                    <div className="border-b border-brandSecondary">
                        <label htmlFor="cat_name" className='text-xs font-bold mb-1 uppercase'>Vimeo Id:</label>
                        <Input type="text" id="cat_name" required={true} value={vimeoId} onChange={onChangeVimeoId}
                               className='border-2 text-input w-1/4 mb-5' validations={[required]}/>
                    </div>

                    <div className="pb-5 text-red-500 font-bold">
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex gap-10">
                        <a href="/" className="btn-secondary w-fit self--start">
                            mégsem
                        </a>
                        <button className="btn-primary w-fit self-start fill-white flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='h-5 w-5'>
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                            </svg>
                            hozzáadás
                        </button>

                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                    </div>

                </Form> : <div className='container mx-auto'>
                    <VideoDetails videoData={videoData} vimeoId={vimeoId}/>
                </div>}
            </div>
        </Layout>
    );
};

export default AddVideo;
