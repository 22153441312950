import {React, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import Layout from "../../components/Layout/Layout";
import classes from './NewPassword.module.scss';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import key from '../../assets/key.svg';


const NewPassword = () => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const submit = (event) => {
        event.preventDefault();
    }

    const onChangePassword = e => {
        const value = e.target.value;
        setPassword(value);
    }
    const onChangePasswordConfirmation = e => {
        const value = e.target.value;
        setPasswordConfirmation(value);
    }

    return (
        <Layout>
            <div className={"flex w-full items-center justify-center " + classes["background"]}>
                <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8">
                    <div className="text-center">
                        <h1 className="font-header font-black text-2xl text-black">GLOSTER VIDEO PORTAL</h1>
                        <p className={'pb-5 border-b-2 border-brandSecondary mb-5 md:mb-12 text-xs'}>Jelszó
                            változtatás</p>
                        <p>
                            Kérjük adja meg az új jelszavát!
                        </p>
                        <div className={'flex flex-col gap-8 mb-5 md:mb-8 items-center'}>
                            <Form onSubmit={submit} className="flex flex-col gap-5 items-center">
                                <div className="flex">
                                    <label htmlFor="password"
                                           className='flex w-8 px-2 bg-bgGray rounded-l-md cursor-pointer'>
                                        <img src={key} alt="felhasználó icon"/>
                                    </label>
                                    <Input type="password" id="password" onChange={onChangePassword}
                                           className="login-input"
                                           placeholder='Új jelszó'/>
                                </div>
                                <div className="flex">
                                    <label htmlFor="passwordConfirmation"
                                           className='flex w-8 px-2 bg-bgGray rounded-l-md cursor-pointer'>
                                        <img src={key} alt="felhasználó icon"/>
                                    </label>
                                    <Input type="password" id="passwordConfirmation"
                                           onChange={onChangePasswordConfirmation}
                                           className="login-input"
                                           placeholder='Új jelszó megerősítése'/>
                                </div>

                                <button type="send" className='btn-primary w-fit'>Új jelszó beállítása</button>
                            </Form>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>);
};

export default NewPassword;
