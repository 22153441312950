import React, {useEffect, useRef, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import userService from "../../../services/user-services";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth";

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback absolute -top-5 text-red-500 text-xs">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const CategoryEdit = () => {
    const form = useRef();
    const checkBtn = useRef();
    const {logout} = useAuth();
    const [category, setCategory] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const categoryID = useParams().id;

    useEffect(() => {
            userService.getCategory(categoryID).then(res => {
                setCategory(res.data.category)
            }, (error) => {
                error.response.status === '403' && logout()
            })
        },
        (error) => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            setMessage(resMessage);
            error.response.statusText === 'Unauthorized' && logout()

        }, [])

    const onChangeCategory = e => {
        const value = e.target.value;
        setCategory(prevState => ({
            ...prevState,
            name: value
        }))
    }

    const handleSubmit = e => {
        e.preventDefault();
        setMessage("");
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            userService.editCategory(categoryID, category.name).then((res) => {
                setSuccess(true)
            }, (error) => {
                const resMessage = error.response.data.validate_err.name[0];
                setMessage(resMessage);
            });
        }
    }
    return (
        <Layout>
            <div className="flex flex-col container mx-auto">
                <div className="heading border-b border-brandSecondary">
                    <h1 className='text-4xl font-bold text-black uppercase'>
                        Kategória szerkesztése
                    </h1>
                </div>
                {!success ? <Form onSubmit={handleSubmit} className='flex my-5 flex-col' ref={form}>

                    <div className="border-b border-brandSecondary">
                        <label htmlFor="cat_name" className='text-xs font-bold mb-1 uppercase'>Kategória neve:</label>
                        <Input type="text" id="cat_name" required={true} value={category.name}
                               onChange={onChangeCategory}
                               className='border-2 text-input w-1/4 mb-5' validations={[required]}/>
                    </div>

                    <div className="pb-5 text-red-500 font-bold">
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex gap-10">
                        <a className="btn-secondary w-fit self--start" href="/kategoriak">
                            mégsem
                        </a>
                        <button className="btn-primary w-fit self-start fill-white flex items-center gap-2">

                            Mentés
                        </button>

                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                    </div>

                </Form> : <>
                    <p className='font-bold my-5'>A kategória módosítása sikeres!</p>
                    <a className="btn-primary w-fit self--start" href="/kategoriak">
                        Visszalépés a listaoldalra
                    </a>
                </>}
            </div>
        </Layout>
    )
        ;
};

export default CategoryEdit;
