import React, {useEffect, useState} from 'react';
import {Navigate, useOutlet} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import userService from "../../services/user-services";

const ProtectedRoute = ({isConfimed}) => {
    const {user} = useAuth();
    const {logout} = useAuth();
    const {storeConfirmed} = useAuth();
    const {storeRole} = useAuth();
    const {isConfirmed} = useAuth();
    const outlet = useOutlet();

    useEffect(() => {
        if (user) {
            userService.getUserData().then(res => {
                storeConfirmed({confirmed: res.data.admin.is_verified});
                storeRole({role: res.data.admin.role});
            }, error => {
                error.response.statusText === 'Unauthorized' && logout()
            });
        }
    }, [])


    if (!user) {
        return <Navigate to="/bejelentkezes"/>;
    }
    if (!isConfirmed.data.confirmed) {
        return <Navigate to="/jelszo-visszaallitas"/>;
    }


    return (
        <div>
            {outlet}
        </div>
    );
}

export default ProtectedRoute;
