import React, {useEffect, useRef, useState} from 'react';
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import Form from "react-validation/build/form";
import userService from "../../../services/user-services";
import CheckButton from "react-validation/build/button";


const required = (value) => {
    if (!value) {
        return (<div className="invalid-feedback absolute -top-5 text-red-500 text-xs">
            Kérjük töltse ki a mezőt!
        </div>);
    }
};


const VideoDetails = ({videoData, vimeoId}) => {
    const form = useRef();
    const checkBtn = useRef();
    const [categories, setCategories] = useState([]);
    const [videoCategories, setVideoCategories] = useState([]);
    const [file, setFile] = useState();
    const [preview, setPreview] = useState(videoData.pictures.base_link);
    const [url, setUrl] = useState(videoData.player_embed_url);
    const [title, setTitle] = useState(videoData.name);
    const [description, setDescription] = useState(videoData.description);
    const [presenter, setPresenter] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [publishedAt, setPublishedAt] = useState('')
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        userService.getCategories().then(result => {
            setCategories(result.data.categories);
        });
    }, []);


    const getCategories = () => {
        return categories.map(category => {
            return <div className='mb-2' key={category.id}>
                <input type="checkbox" id={category.id}
                       className={'accent-brandPrimary hidden peer'} onChange={(e) => {
                    const categoryArray = videoCategories;
                    if (e.target.checked) {
                        categoryArray.push(category.id);
                    } else {
                        const index = categoryArray.indexOf(category.id);
                        if (index > -1) {
                            categoryArray.splice(index, 1);
                        }
                    }
                    setVideoCategories(categoryArray);
                }}/>
                <label htmlFor={category.id}
                       className={"text-xs border bg-lightgray border-brandPrimary py-1 px-3 rounded-full font-montserratM peer-checked:bg-brandPrimary peer-checked:text-white hover:bg-brandPrimary hover:text-white transition-all"}> {category.name}</label>
            </div>
        })
    }

    const onChangeUrl = e => {
        const value = e.target.value;
        setUrl(value)
    };
    const onChangeTitle = e => {
        const value = e.target.value;
        setTitle(value)
    };
    const onChangeDescription = e => {
        const value = e.target.value;
        setDescription(value)
    };
    const onChangePresenter = e => {
        const value = e.target.value;
        setPresenter(value)
    };
    const onAddImage = e => {
        window.URL.revokeObjectURL(preview);
        setPreview(window.URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('vimeo_id', vimeoId,);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('thumbnail_url', videoData.pictures.base_link);
        file && formData.append('thumbnail_upload', file);
        formData.append('embed_code', url);
        formData.append('presenter', presenter);
        formData.append('is_visible', isVisible ? "1" : '0');
        formData.append('is_public', isPublic ? "1" : '0');
        formData.append('published_at', publishedAt);
        videoCategories.map(cat => formData.append('category_ids[]', cat));
        userService.addVideo(formData).then(res => {
            setSuccess(true)
        }, (error) => {
            const resMessage = Object.values(error.response.data.error)[0];
            setMessage(resMessage);
        })
    }
    return (<>{
        success ? <>   <p className='font-bold my-5'>Új videó hozzáadása sikeres!</p>
            <a className="btn-primary w-fit self--start" href="/">
                Visszalépés a listaoldalra
            </a></> : <div>
            {message && (<div className="form-group text-xl text-red-500 my-2 font-black">
                <div className="alert alert-danger" role="alert">
                    {message}
                </div>
            </div>)}
            <Form onSubmit={handleSubmit} className='flex py-5 flex-col' ref={form}>
                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-5 mx-10">
                    <div className="">
                        <div className="">
                            <strong>
                                Előnézet:
                            </strong>
                            <div className="my-10">
                                <iframe src={url} frameBorder="0" width='100%' height='600' allowFullScreen
                                        allow={'fullscreen'}
                                        className={'max-h-[300px] h-[calc(100vw *.5625)]'}></iframe>

                            </div>
                        </div>
                        <div className="">
                            <label htmlFor="url" className='text-xs font-bold mb-1 uppercase'>Video URL:</label>
                            <Input type="text" id="url" required={true} value={url} onChange={onChangeUrl}
                                   disabled={true}
                                   className='border-2 text-input w-full mb-5 disabled:bg-gray-100 disabled:border-gray-300'
                                   validations={[required]}/>
                        </div>
                        <div className="">
                            <label htmlFor="title" className='text-xs font-bold mb-1 uppercase'>Video Címe:</label>
                            <Input type="text" id="title" required={true} value={title} onChange={onChangeTitle}
                                   className='border-2 text-input w-full mb-5' validations={[required]}/>
                        </div>
                        <div className="">
                            <label htmlFor="description" className='text-xs font-bold mb-1 uppercase'>Video
                                leírása:</label>
                            <Textarea type="text" id="description" required={true} value={description}
                                      onChange={onChangeDescription}
                                      className='border-2 text-input w-full mb-5'
                                      validations={[required]}/>
                        </div>
                        <div className="">
                            <label htmlFor="presenter" className='text-xs font-bold mb-1 uppercase'>Video
                                előadója:</label>
                            <Input type="text" id="presenter" required={true} value={presenter}
                                   onChange={onChangePresenter}
                                   className='border-2 text-input w-full mb-5' validations={[required]}/>
                        </div>
                        <div className="">
                            <label htmlFor="published_at" className='text-xs font-bold mb-1 uppercase'>Publikálás
                                napja:</label>
                            <Input type="date" id="published_at" required={true} value={publishedAt}
                                   onChange={e => {
                                       setPublishedAt(e.target.value)
                                   }}
                                   className='border-2 text-input w-full mb-5' validations={[required]}/>
                        </div>
                        <div className="flex gap-x-3 items-center mb-2">
                            <input type="checkbox" id="isVisible" defaultChecked={isVisible}
                                   onChange={() => setIsVisible(!isVisible)}
                                   className='border-2 text-input accent-baseText'/>
                            <label htmlFor="isVisible" className='text-xs font-bold  uppercase my-auto'>A videó
                                látható a
                                felhasználók számára</label>
                        </div>
                        <div className="flex gap-x-3 items-center">
                            <input type="checkbox" id="isPublic" defaultChecked={isPublic}
                                   name="isPublic"
                                   onChange={() => setIsPublic(!isPublic)}
                                   className='border-2 text-input  accent-baseText'/>
                            <label htmlFor="isPublic" className='text-xs font-bold  uppercase my-auto'>A videó
                                elérhető külső felhasználók számára</label>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-col gap-y-10 mb-5">
                            <div className="flex-col">
                                <label htmlFor="file"
                                       className='btn-primary'>Indexkép
                                    feltöltése</label>
                                <Input filename={file}
                                       onChange={onAddImage}
                                       type="file"
                                       accept="image/*" id="file" className="hidden peer"/>
                                {file &&
                                    <div className="btn-secondary border-red-500 hover:bg-red-500 w-fit mt-5"
                                         onClick={() => {
                                             setFile(null);
                                             setPreview(videoData.pictures.base_link);
                                         }}>Mégsem</div>}
                            </div>
                            <img src={preview} alt="" className={'max-h-[300px] object-contain'}/>
                        </div>
                        <div className=" flex flex-col gap-x-3 ">
                            <label htmlFor="cat_name"
                                   className='text-xs font-bold mb-1 uppercase my-auto'>Kategóriák</label>
                            {getCategories()}
                        </div>
                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                    </div>
                </div>
                <div className="flex border-t border-brandSecondary items-center justify-between mt-4 pt-4"><a
                    href="/" className='btn-secondary'>Mégsem</a>
                    <button type="send" className='btn-primary'> Mentés</button>
                </div>

            </Form>
        </div>
    }</>);
};

export default VideoDetails;
