import {useAuth} from "../../hooks/useAuth";
import logo from "../../assets/logo_old.svg";
import open from "../../assets/btn-menu.svg";
import close from "../../assets/btn-menu-close.svg";

import React, {useState} from "react";
import {NavLink} from "react-router-dom";


const Nav = () => {

    const {user} = useAuth();
    const {role} = useAuth();
    const {logout} = useAuth();
    const [navbarOpen, setNavbarOpen] = useState(false);

    const handleToggle = () => {
        setNavbarOpen(prev => !prev)
    }


    return (
        <nav
            className="p-3 border-b border-navbar">


            <div className={'container mx-auto flex items-center justify-between'}><a href="/"> <img src={logo}
                                                                                                     alt="Gloster Logo"
                                                                                                     className={'px-8 w-[200px]'}/></a>

                <button className={'px-8 md:hidden'}>{navbarOpen ? <img src={close} alt="" onClick={handleToggle}/> :
                    <img src={open} alt="" onClick={handleToggle}/>}</button>

                {user &&
                    <>
                        <NavLink to="/" className='menu-link'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className='w-4 h-4 mr-2'>
                                <path
                                    d="M256 0H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM64 128h96V384v32c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V384H512v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z"/>
                            </svg>
                            Videók</NavLink>
                        <NavLink to="/kategoriak" className='menu-link'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className='w-4 h-4 mr-2'>
                                <path
                                    d="M572.6 270.3l-96 192C471.2 473.2 460.1 480 447.1 480H64c-35.35 0-64-28.66-64-64V96c0-35.34 28.65-64 64-64h117.5c16.97 0 33.25 6.742 45.26 18.75L275.9 96H416c35.35 0 64 28.66 64 64v32h-48V160c0-8.824-7.178-16-16-16H256L192.8 84.69C189.8 81.66 185.8 80 181.5 80H64C55.18 80 48 87.18 48 96v288l71.16-142.3C124.6 230.8 135.7 224 147.8 224h396.2C567.7 224 583.2 249 572.6 270.3z"/>
                            </svg>
                            Kategóriák</NavLink>
                        {role.data.role === "Super Administrator" && <NavLink to="/felhasznalok" className='menu-link'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className='w-4 h-4 mr-2'>
                                <path
                                    d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/>
                            </svg>
                            Felhasználók</NavLink>
                        }

                        <button onClick={() => logout()}
                                className={'text-buttonPrimary hover:text-buttonHover text-xs font-montserratM hidden md:block'}>
                            Kijelentkezés
                        </button>
                    </>
                }
            </div>

        </nav>
    );
}

export default Nav;
