import {React, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import classes from './ForgotPW.module.scss';

import at from '../../assets/at-solid.svg';
import AuthService from "../../services/auth-services";


const ForgotPW = () => {
    const [email, setEmail] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const handlePWReset = (event) => {
        event.preventDefault();
        AuthService.forgotPW(email).then(() => {
            setMessageSent(true)
        })
        ;
    }

    const onChangeEmail = e => {
        const value = e.target.value;
        setEmail(value)
    }

    return (<Layout>
        <div className={"flex w-full items-center justify-center " + classes["background"]}>
            <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8">
                <div className="text-center">
                    <h1 className="font-header font-black text-2xl text-black mb-2">GLOSTER VIDEO PORTAL</h1>
                    <p className={'pb-5 border-b-2 border-brandSecondary mb-5 md:mb-12 text-xs text-black'}>Elfelejtett
                        jelszó</p>
                    <div className={'flex flex-col gap-8 mb-5 md:mb-8 items-center'}>
                        {!messageSent ?
                            <>
                                <form onSubmit={handlePWReset} className="flex flex-col gap-5 items-center">
                                    <div className="flex">
                                        <label htmlFor="username"
                                               className='flex w-8 px-2 bg-bgGray rounded-l-md cursor-pointer'>
                                            <img src={at} alt="felhasználó icon"/>
                                        </label>
                                        <input type="email" id="username" onChange={onChangeEmail}
                                               className="login-input"
                                               placeholder='Email-cím'/>
                                    </div>

                                    <button type="send" className='btn-primary w-fit'>Új jelszót kérek
                                    </button>
                                </form>
                            </>
                            :
                            <>
                                <div className="flex justify-center items-center w-full">
                                    <svg className={classes["icon"] + " " + classes["plane"]}>
                                        <polyline points="119,1 1,59 106,80 119,1"></polyline>
                                        <polyline points="119,1 40,67 43,105 69,73"></polyline>
                                    </svg>
                                    <p className="text-brandPrimary font-black text-left">
                                        Az új jelszót tartalmazó email-t elküldtük a megadott e-mail címre.
                                    </p>
                                </div>

                            </>
                        }
                    </div>
                    <a href="/bejelentkezes"
                       className='link-primary'>Vissza a bejelentkezési felületre</a>
                </div>

            </div>
        </div>
    </Layout>);
};

export default ForgotPW;
