import React from 'react';
import Nav from "../Routing/Nav";
import classes from "../../pages/Home/Home.module.scss";


const Layout = (props) => {
    return (
        <div className={'min-h-100'}>
            <Nav/>
            <div className={`flex w-full ${props.padded && "px-4"} ` + classes["background"]}>
                {props.children}
            </div>

        </div>
    );
};

export default Layout;
