import axios from "axios";

const API_URL = "https://api.vimeo.com/";


const getVideoDataByID = id => {
    return axios.get(API_URL + "me/videos/" + id, {
        headers: {
            Authorization: 'Bearer b2d7e8af2c54c5183b5e73dd4305e30d',
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            'Content-Type': 'application/json'
        }
    })
}

const videoServices = {getVideoDataByID}

export default videoServices
